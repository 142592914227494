//logout action
export const CLEAR_ADMIN_DATA = 'CLEAR_ADMIN_DATA';
export const clearAdmin = () => {
    return {
        type: CLEAR_ADMIN_DATA
    };
}
//reset notification 
export const RESET_MSG = 'RESET_MSG';
export const resetNotificaitons = () => {
    return {
        type: RESET_MSG
    }
}

//get History
export const GET_ADMIN_ASSESSMENT_HISTORY = 'GET_ADMIN_ASSESSMENT_HISTORY';
export const adminHistoryRequest = () => {
    return {
        type: GET_ADMIN_ASSESSMENT_HISTORY, 
    };
}
export const GET_ADMIN_ASSESSMENT_HISTORY_SUCCESS = 'GET_ADMIN_ASSESSMENT_HISTORY_SUCCESS';
export const adminHistorySuccess = data => {
    return {
        type: GET_ADMIN_ASSESSMENT_HISTORY_SUCCESS,
        data
    };
}
export const GET_ADMIN_ASSESSMENT_HISTORY_FAILURE = 'GET_ADMIN_ASSESSMENT_HISTORY_FAILURE';
export const adminHistoryFailure = (message) => {
    return {
        type: GET_ADMIN_ASSESSMENT_HISTORY_FAILURE,
        message
    };
}

//get History
export const GET_ADMIN_AUDIT_HISTORY = 'GET_ADMIN_AUDIT_HISTORY';
export const adminAuditRequest = () => {
    return {
        type: GET_ADMIN_AUDIT_HISTORY, 
    };
}
export const GET_ADMIN_AUDIT_HISTORY_SUCCESS = 'GET_ADMIN_AUDIT_HISTORY_SUCCESS';
export const adminAuditSuccess = data => {
    return {
        type: GET_ADMIN_AUDIT_HISTORY_SUCCESS,
        data
    };
}
export const GET_ADMIN_AUDIT_HISTORY_FAILURE = 'GET_ADMIN_AUDIT_HISTORY_FAILURE';
export const adminAuditFailure = (message) => {
    return {
        type: GET_ADMIN_AUDIT_HISTORY_FAILURE,
        message
    };
}

//get reports
export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST';
export const adminReportRequest = () => {
    return {
        type: GET_REPORTS_REQUEST, 
    };
}
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const adminReportSuccess = data => {
    return {
        type: GET_REPORTS_SUCCESS,
        data
    };
}
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';
export const adminReporFailure = (message) => {
    return {
        type: GET_REPORTS_FAILURE,
        message
    };
}


//update reports
export const UPDATE_REPORTS_REQUEST = 'UPDATE_REPORTS_REQUEST';
export const updateReportRequest = () => {
    return {
        type: UPDATE_REPORTS_REQUEST,
    };
}
export const UPDATE_REPORTS_SUCCESS = 'UPDATE_REPORTS_SUCCESS';
export const updateReportSuccess = data => {
    return {
        type: UPDATE_REPORTS_SUCCESS,
        data
    };
}
export const UPDATE_REPORTS_FAILURE = 'UPDATE_REPORTS_FAILURE';
export const updateReportFailure = (message) => {
    return {
        type: UPDATE_REPORTS_FAILURE,
        message
    };
}

//get reports
export const GET_CODES_REQUEST = 'GET_CODES_REQUEST';
export const accessCodesRequest = () => {
    return {
        type: GET_CODES_REQUEST,
    };
}
export const GET_CODES_SUCCESS = 'GET_CODES_SUCCESS';
export const accessCodesSuccess = data => {
    return {
        type: GET_CODES_SUCCESS,
        data
    };
}
export const GET_CODES_FAILURE = 'GET_CODES_FAILURE';
export const accessCodesFailure = (message) => {
    return {
        type: GET_CODES_FAILURE,
        message
    };
}

//delete codes
export const DELETE_CODE_REQUEST = 'DELETE_CODE_REQUEST';
export const deleteCodeRequest = () => {
    return {
        type: DELETE_CODE_REQUEST,
    };
}
export const DELETE_CODES_SUCCESS = 'DELETE_CODES_SUCCESS';
export const deleteCodeSuccess = data => {
    return {
        type: DELETE_CODES_SUCCESS,
        data
    };
}
export const DELETE_CODES_FAILURE = 'DELETE_CODES_FAILURE';
export const deleteCodeFailure = (message) => {
    return {
        type: DELETE_CODES_FAILURE,
        message
    };
}
