import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Checkbox, FormControlLabel, Modal, Typography } from '@material-ui/core';

import TermsCard from 'src/components/shared/TermsCard';
import { userAcceptToC, logoutUser } from "src/redux/thunks";


const AcceptTermsModal = ({ isVisible, handleClose, logout }) => {
    const classes = useStyles();
    const [isChecked, setIsChecked] = useState(false);

    const userInfo = useSelector(state => state.auth.userInfo)

    const dispatch = useDispatch();

    const onDecline = () => {
        dispatch(logoutUser())
    }

    const onAccept = () => {

        dispatch(userAcceptToC(userInfo))

        if (handleClose) handleClose();
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    return (
        <Modal
            aria-labelledby="terms-modal-title"
            aria-describedby="terms-modal-description"
            open={isVisible}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    onAccept();
                }
            }}
            disableEscapeKeyDown // Disables closing with the Escape key
        >
            <Box className={classes.content}>
                <Typography variant="h2" className={classes.label}>
                    We have updated our Terms and Conditions
                </Typography>
                <TermsCard />

                <Typography variant="body1" className={classes.label}>
                    We have updated our Terms and Conditions of Service and Use to protect against your company data and our proprietary algorithms being used in third-party machine learning / artificial intelligence without permission.
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            name="acceptTerms"
                            color="primary"
                        />
                    }
                    label="I agree to the Terms and Conditions"
                    className={classes.centeredCheckbox} // Center the checkbox
                />

                <Box className={classes.buttonContainer}>
                    <Button
                        size="large"
                        color="error"
                        variant="contained"
                        onClick={onDecline}
                    >
                        Close
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        onClick={onAccept}
                        disabled={!isChecked} // Disables the button if checkbox is unchecked
                    >
                        Accept
                    </Button>
                </Box>

            </Box>
        </Modal>
    );
};


const useStyles = makeStyles(() => ({
    content: {
        padding: 10,
        overflowX: 'hidden',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: 640,
        width: 770,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        alignItems: "center" // Center items horizontally
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 200
    },
    centeredCheckbox: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    label: {
        marginTop: '16px',
        textAlign: "center",
        fontWeight: "bold",
    },
}));

export default AcceptTermsModal
