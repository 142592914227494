import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Box, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

const Introduction = (props) => {

    // const {
    //     header,
    //     paragraphs,
    // } = props;

    const classes = useStyles();

    return (
        <Box className={classes.container} pl={8} pr={10} mt={4}>
            <Box sx={{ display: 'flex' }} mb={4}>
                <Box>
                    <img width="100px" alt="Logo" src="/static/images/best-practices.png" />
                </Box>
                <Box ml={8}>
                    <Typography color="textPrimary" variant="h1">Instructions</Typography>
                    <Typography color="textPrimary" gutterBottom variant="body1" fontSize={20}>
                        Best practices to get the most out of your assessment
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box mt={4}>
                <ol style={{ fontFamily: 'Arial', fontSize: 18 }}>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 18 }}>
                            It is recommended you take the time and thoroughly discuss the statements and level descriptions with the people “who know” your actual status conditions. If alone this only takes about 30 minutes. If you take the assessment in a group setting it could take up to a couple of hours to discuss everything.
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 18 }} mt={2}>
                            Assessor(s) should have in mind a specific organizational boundary of what is being examined and what isn't. Knowing that, think of supporting evidence or actual activities that demonstrate your rating; such as, internal skills and expertise, written certifications or awards, process documentation, contracts and agreements, outside reviews or audits, etc.
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 18 }} mt={2}>
                            As you rate each process, make a note on a separate page of the experience or situation that either exists or is missing which caused you to rate that particular Level. This will help remind you of your ratings when you receive your . And, if you use a consultant or mentor in the future, the <b>Report</b> plus this additional information will help get them up to speed quickly and more efficiently.
                        </Typography>
                    </li>

                    <Box sx={{ float: 'right' }} ml={4} my={2}>
                        <img width="600px" alt="Logo" src="/static/images/vsba/intro-devscale.png" />
                    </Box>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 18 }} mt={2}>
                            Review the <i><u>Maturity & Development Scale</u></i> below and understand its implications so you can accurately rate your current-state processes with a <b>Level Rating</b> from <b>0 to 3.</b>
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 18 }} mt={2}>
                            Use the <i><u>Maturity & Development Scale</u></i> to guide your ratings of how
                            well your organization currently achieves the <i><u>Process Maturity Statements</u></i> within each process Category.
                            Read all four Maturity <i><u>Level Descriptions</u></i> <b><i>from bottom to top.</i></b> Rate the Level that best
                            represents your organization's current maturity Level for each
                            process by either sliding the selector or typing the Level number in
                            the box.
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 18 }} mt={2}>
                            You will see four <b>Process Categories.</b> Each one will have several <b>Processes</b> associated with it.  And each Process will have a label, a statement of the aspiration condition, and <b>Maturity Level</b> descriptions with aspects of various pieces and parts of evidence of what you might see if you were operating at that maturity level.
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 18 }} mt={2}>
                            While you may initially think you have achieved a Level 2, if your Project Team has issues meeting all aspects of Level 2, you must rate yourselves lower at Level 1.   In other words, your Project Team <i>must have accumulated all of the positive accomplishments of the lower Levels before you can select a higher Level</i> as your rating.
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom variant="body1" style={{ fontSize: 18 }} mt={2}>
                            Should you “Quit” prior to completion, you will lose all collected data to that point and will have to start over again from the first statement. We do this because status is fleeting and it is important to have ratings all based in the same time frame.
                        </Typography>
                    </li>
                </ol>
                <Typography gutterBottom variant="body1" style={{ fontSize: 18 }} mt={6}>
                    We hope you discover something new, or have something you already know reinforced, by taking the assessment.  Best wishes to you for a prosperous future.
                </Typography>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1250
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

// Introduction.propTypes = {
//     header: PropTypes.string.isRequired,
//     paragraphs: PropTypes.array.isRequired,
// }

export default Introduction;

