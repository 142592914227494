import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TableSortLabel, Toolbar, Typography, Paper, Checkbox, Button
} from '@material-ui/core';
import moment from 'moment';
import TableSpinner from './TableSpinner';

// Utility functions
const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
};

const getComparator = (order, orderBy) =>
    order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);

const stableSort = (array, comparator) => {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        return order !== 0 ? order : a[1] - b[1];
    });
    return stabilizedArray.map(el => el[0]);
};

// Styles
const useStyles = makeStyles((theme) => ({
    root: { width: '100%' },
    paper: { width: '100%', marginBottom: theme.spacing(2) },
    table: { minWidth: 750 },
    title: { flex: '1 1 100%', paddingLeft: 25 },
}));

// Toolbar Component
const EnhancedTableToolbar = ({ numSelected, checkBox, apiButton, toolbarButtonText, onDisplayGrouping, onExport }) => {
    const classes = useStyles();


    const _color = toolbarButtonText ? 'black' : 'white'
    const _backgroundColor = toolbarButtonText ? '#8edb91' : '#4caf50'

    return (
        <Toolbar className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })}>
            <Typography className={classes.title} variant="h4" color="textSecondary">
                Select to View and/or download an assessment report.
            </Typography>
            {/* {apiButton && (
                <Button
                    onClick={onExport}
                    variant="contained"
                    sx={{ mt: 1, mr: 1, width: 150, height: 50, color: _color, backgroundColor: _backgroundColor, '&:hover': { backgroundColor: '#357a38' } }}
                >
                    Export Matcher
                </Button>
            )} */}
            {checkBox && (
                <Button
                    onClick={onDisplayGrouping}
                    variant="contained"
                    sx={{ color: _color, mt: 1, width: 120, height: 50, backgroundColor: _backgroundColor, '&:hover': { backgroundColor: '#357a38' } }}
                >
                    {toolbarButtonText || 'Display Grouping'}
                </Button>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    checkBox: PropTypes.bool,
    apiButton: PropTypes.bool,
    toolbarButtonText: PropTypes.string,
    onDisplayGrouping: PropTypes.func.isRequired,
    onExport: PropTypes.func
};

// Table Head Component
const EnhancedTableHead = ({ order, orderBy, onRequestSort, headCells }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id !== 'reports' && headCell.id !== 'include' ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        ) : (
                            headCell.label
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    headCells: PropTypes.array.isRequired,
};

// Main Component
const EnhancedTable = ({ headCells, rows, isLoading, openDownload, openSelected, checkBox, apiButton, toolbarButtonText, onExportGrouping }) => {
    const classes = useStyles();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('');
    const [selected, setSelected] = useState([]);

    const [data, setData] = useState(rows);

    useEffect(() => {
        if (rows?.length > 0) {
            const sorted = [...rows].sort((a, b) => b.dateAssessed.seconds - a.dateAssessed.seconds);
            setData(sorted);
        }
    }, [rows]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelect = (checked, item) => {
        if (checked && selected.length < 8) {
            setSelected([...selected, item]);
        } else {
            setSelected(selected.filter((i) => i.accessCode !== item.accessCode));
        }
    };

    const isSelected = (accessCode) => selected.some((item) => item.accessCode === accessCode);

    const handleDisplayGrouping = () => {
        if (selected.length > 0) openSelected(selected);
    };

    const handleExportGrouping = () => {
        if (selected.length > 0) onExportGrouping(selected)
    }

    const formatDate = (seconds) => moment(seconds * 1000).format('MM/DD/YYYY');

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    checkBox={checkBox}
                    apiButton={apiButton}
                    toolbarButtonText={toolbarButtonText}
                    onDisplayGrouping={handleDisplayGrouping}
                    onExport={handleExportGrouping}
                />
                <TableContainer>
                    <Table className={classes.table} aria-labelledby="tableTitle" size="medium">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {data && data.length > 0 ? (
                                stableSort(data, getComparator(order, orderBy)).map((row, index) => {
                                    const isItemSelected = isSelected(row.accessCode);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => handleSelect(!isItemSelected, row)}
                                            role="checkbox"
                                            key={`assessment-${index}`}
                                            selected={isItemSelected}
                                        >
                                            <TableCell>{row.organizationAssessed}</TableCell>
                                            <TableCell>{row.project}</TableCell>
                                            <TableCell>{formatDate(row.dateAssessed.seconds)}</TableCell>
                                            <TableCell>{`.... ${row.accessCode.slice(-5)}`}</TableCell>
                                            <TableCell>{row.type === 'VSBA' ? 'VIA·Biz' : row.type}</TableCell>
                                            <TableCell>{row.assessorSortName}</TableCell>
                                            <TableCell>{row.owner?.email}</TableCell>
                                            {checkBox && (
                                                <TableCell>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        onChange={(e) => handleSelect(e.target.checked, row)}
                                                    />
                                                </TableCell>
                                            )}
                                            {!checkBox && (
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => openDownload(row)}
                                                        sx={{
                                                            width: 100,
                                                            backgroundColor: '#4caf50',
                                                            '&:hover': { backgroundColor: '#357a38' },
                                                        }}
                                                    >
                                                        View
                                                    </Button>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableSpinner isLoading={isLoading} colSpan={headCells.length} />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

EnhancedTable.propTypes = {
    headCells: PropTypes.array.isRequired,
    rows: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    openDownload: PropTypes.func,
    openSelected: PropTypes.func,
    checkBox: PropTypes.bool,
    apiButton: PropTypes.bool,
    toolbarButtonText: PropTypes.string,
};

export default EnhancedTable;
