export const RESET_AUTH_MSG = 'RESET_AUTH_MSG';
export const hideAuthNotification = () => {
    return {
        type: RESET_AUTH_MSG
    }
}
//login
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const requestLogin = () => {
    return {
        type: LOGIN_REQUEST
    };
}; 
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const receiveLogin = user => {
    return {
        type: LOGIN_SUCCESS,
        user
    };
};   
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const loginError = message => {
    return {
        type: LOGIN_FAILURE,
        message
    };
};

//logout
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const requestLogout = () => {
    return {
        type: LOGOUT_REQUEST
    };
}; 
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const receiveLogout = () => {
    return {
        type: LOGOUT_SUCCESS
    };
};
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const logoutError = message => {
    return {
        type: LOGOUT_FAILURE,
        message
    };
}; 

//verify
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const verifyRequest = () => {
    return {
        type: VERIFY_REQUEST
    };
}; 
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const verifySuccess = () => {
    return {
        type: VERIFY_SUCCESS
    };
};
export const VERIFY_FAILURE = "VERIFY_FAILURE";
export const verifyFailure = () => {
    return {
        type: VERIFY_FAILURE
    }
}

//register
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const requestRegistration = () => {
    return {
        type: REGISTER_REQUEST
    };
};
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const receiveRegistration = user => {
    return {
        type: REGISTER_SUCCESS,
        user
    };
}; 
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const registrationError = message => {
    return {
        type: REGISTER_FAILURE,
        message 
    };
};

//reset password
export const RESET_REQUEST = "RESET_REQUEST";
export const requestReset = () => {
    return {
        type: RESET_REQUEST
    } 
};
export const RESET_SUCCESS = "RESET_SUCCESS";
export const recieveReset = () => {
    return {
        type: RESET_SUCCESS
    }
}
export const RESET_FAILURE = "RESET_FAILURE";
export const resetError = message => {
    return {
        type: RESET_FAILURE,
        message
    }
}

//get user
export const USER_SUCCESS = "USER_SUCCESS";
export const receiveUser = userInfo => {
    return {
        type: USER_SUCCESS,
        userInfo
    };
}; 


//update dev mode
export const UPDATE_DEVMODE = "UPDATE_DEVMODE";
export const updateDevMode = bool => {
    return {
        type: UPDATE_DEVMODE,
        bool
    };
}; 

//update admin UPDATE_ADMIN
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const updateAdmin = bool => {
    return {
        type: UPDATE_ADMIN,
        bool
    };
}; 


//verify access code
export const VERIFY_CODE_REQUEST = "VERIFY_CODE_REQUEST";
export const verifyCodeRequest = () => {
    return {
        type: VERIFY_CODE_REQUEST
    }
}
export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
export const verifyCodeSuccess = (data) => {
    return {
        type: VERIFY_CODE_SUCCESS,
        data
    }
}
export const VERIFY_CODE_FAILURE = "VERIFY_CODE_FAILURE";
export const verifyCodeFailure = message => {
    return {
        type: VERIFY_CODE_FAILURE,
        message
    }
}
export const VERIFY_CODE_RESET = "VERIFY_CODE_RESET";
export const verifyCodeReset = () => {
    return {
        type: VERIFY_CODE_RESET
    }
}

//verify email
export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const verifyEmailRequest = () => {
    return {
        type: VERIFY_EMAIL_REQUEST
    }
}
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const verifyEmailSuccess = (data) => {
    return {
        type: VERIFY_EMAIL_SUCCESS,
        data
    }
}
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";
export const verifyEmailFailure = message => {
    return {
        type: VERIFY_EMAIL_FAILURE,
        message
    }
}
export const VERIFY_EMAIL_RESET = "VERIFY_EMAIL_RESET";
export const verifyEmailReset = () => {
    return {
        type: VERIFY_EMAIL_RESET
    }
}

export const NEEDS_REGIS = "NEEDS_REGIS";
export const setRegistation = data => {
    return {
        type: NEEDS_REGIS,
        data
    }
}
export const SET_CODE = "SET_CODE";
export const setCode = data => {
    return {
        type: SET_CODE,
        data
    }
}
export const SET_PASSWORD = "SET_PASSWORD";
export const setPassword = data => {
    return {
        type: SET_PASSWORD,
        data
    }
}
export const SET_EMAIL = "SET_EMAIL";
export const setEmail = data => {
    return {
        type: SET_EMAIL,
        data
    }
}
export const SET_NEW_ASSETS = 'SET_NEW_ASSETS'
export const setNewAssets = data => {
    return {
        type: SET_NEW_ASSETS,
        data
    }
}
export const RESET_NEW_ASSETS = 'RESET_NEW_ASSETS'
export const resetNewAssets = () => {
    return {
        type: RESET_NEW_ASSETS
    }
}

export const SET_WELCOME_ID = "SET_WELCOME_ID";
export const setWelcomeID = data => {
    return {
        type: SET_WELCOME_ID,
        data
    }
}

export const SET_NAVIGATION = 'SET_NAVIGATION'
export const setNavigation = data => {
    return {
        type: SET_NAVIGATION,
        data
    }
}

export const SET_RESET_LOGIN_FIELDS = 'SET_RESET_LOGIN_FIELDS'
export const setResetLoginFields = data => {
    return {
        type: SET_RESET_LOGIN_FIELDS,
        data
    }
}

export const SET_RESET_CODE_FIELDS = 'SET_RESET_CODE_FIELDS'
export const setResetCodeFields = data => {
    return {
        type: SET_RESET_CODE_FIELDS,
        data
    }
}

export const UPDATE_TOC_FIELD = 'UPDATE_TOC_FIELD'
export const updateToCField = data => {
    return {
        type: UPDATE_TOC_FIELD,
        data
    }
}

