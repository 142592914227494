import { firebase, db, timestamp } from "../../services/firebase"

export const createSession = async (email, type) => {

    try {
        if(type === 'audit') {
            const createAuditSession = firebase.functions().httpsCallable('createAuditSession')

            return await createAuditSession({ email })
        } else {
            const createStripeSession = firebase.functions().httpsCallable('createStripeSession')

            return await createStripeSession({ email })
        }
     

    } catch (err) {
        console.log("catch error: ", err)
    }
}

export const getSessionStatus = async (sessionId) => {
    try {
        const retriveStripeSession = firebase.functions().httpsCallable('retrieveStripeSession')
        return await retriveStripeSession({ sessionId })
    } catch (err) {
        console.log("err: ", err)
    }
}

export const getLineItems = async (sessionId) => {
    try {
        const retriveLineItems = firebase.functions().httpsCallable('listLineItems')
        let response = await retriveLineItems({ sessionId })
        return response.data
    } catch (err) {
        console.log("err: ", err)
    }
}

export const createAccessCode = async (assesment, purchaser, payment, price) => {

    try {
        //create purchase record
        const query = db.collection('purchaseHistory').where('purchaseId', '==', payment.id)

        let querySnapshot = await query.get()

        if (querySnapshot.empty) {
            const data = {
                accessCode: '',
                assessor: {
                    email: '',
                    name: '',
                    isClaimed: false
                },
                cost: parseFloat(price / 100),
                date: timestamp,
                isDiscounted: false,
                owner: {
                    email: purchaser,
                    name: payment.customer_details.name,
                    isClaimed: false
                },
                paymentMethod: 'Credit Card', //todo: figure out other options
                purchaserEmail: purchaser ? purchaser : '',
                purchaseId: payment.id,
                paymentStatus: payment.payment_status,
                status: 'Available',
                storeId: assesment?.id ? assesment.id : 'vsba',
                type: assesment?.type ? assesment.type : 'VIA Biz',
                isExpired: false,
                isCompleted: false,
            }

            const document = await db.collection('purchaseHistory').add(data)

            //TODO: return error dispatch
            if (!document.id) {
                console.log('No document.id returned')
                return
            } else {
                //get document and set accesscode
                await db.collection('purchaseHistory').doc(document.id).update({ accessCode: document.id })
                return document.id
            }
        } else {
            let codes = querySnapshot.docs.map(doc => doc.data().accessCode)
            return codes
        }
    } catch (error) {
        console.log('error: ', error)
    }
}