
import React, { useEffect, useState, useRef } from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useReactToPrint } from 'react-to-print'
import { useLocation, NavLink as RouterLink } from 'react-router-dom'
import Footer from 'src/components/shared/Footer'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { calcAxisSort } from 'src/redux/reducers/takingAssessment'
import { parseProcessName } from './utils'

const style = {
    borderBottom: '1px solid black',
    textAlign: 'center',
    width: 50,
}

const getQuad = (vulnerable) => {

    switch (vulnerable) {
        case 'Q1':
            return 'QI'
        case 'Q2':
            return 'QII'
        case 'Q3':
            return 'QIII'
        case 'Q4':
            return 'QIV'
    }
}

const generateStyle = (number) => {
    switch (number) {
        case 0:
            return {
                ...style,
                backgroundColor: '#fa9b94',
            }
        case 1:
            return {
                ...style,
                backgroundColor: '#ff0',
            }
        case 2:
            return {
                ...style,
                backgroundColor: '#8dfd6f',
            }
        case 3:
            return {
                ...style,
                backgroundColor: '#0070c0',
                color: '#fff'
            }
        default:
            return style
    }
}


const parseCategory = (category) => {

    if (category === 'COMMERCIALIZATION: Category of Processes' || category === 'COMMERCIALIZATION') {
        return 'Commercialization'
    } else {
        return category
    }
}

const HeatMap = () => {
    const printRef = useRef()
    const promiseResolveRef = useRef(null)

    const user = useSelector(state => state.auth.userInfo)

    let location = useLocation()
    const [reports, setReports] = useState([])
    const [data, setData] = useState([])
    const [defaultData, setDefaultData] = useState([])

    const [sortOrder, setSortOrder] = useState('default')
    const [assessmentSortOrder, setAssessmentSortOrder] = useState('default')

    const [isPrinting, setIsPrinting] = useState(false)
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current()
        }
    }, [isPrinting])

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])


    useEffect(() => {
        if (location) {
            const originalData = location.state?.data
            if (originalData) {
                const transformedData = transformData(originalData)
                setData(transformedData)
                setDefaultData(transformedData)

                let _data = generateTableData(transformedData)
                setReports(_data)
            }
        }
    }, [location])

    useEffect(() => {
        const getSortFunction = (order) => {
            const sortFunctions = {
                'timeOldToNew': (a, b) => a.dateAssessed - b.dateAssessed,
                'timeNewToOld': (a, b) => b.dateAssessed - a.dateAssessed,
                'asc': (a, b) => a.average - b.average,
                'desc': (a, b) => b.average - a.average,
            }
            return sortFunctions[order] || (() => 0)
        }



        if (defaultData.length > 0) {
            const sortedData = assessmentSortOrder === 'default'
                ? [...defaultData]
                : [...data].sort(getSortFunction(assessmentSortOrder))
            const _data = generateTableData(sortedData)

            const sortedReport = (sortOrder === 'asc' || sortOrder === 'desc')
                ? [..._data].sort(getSortFunction(sortOrder))
                : _data

            setData(sortedData)
            setReports(sortedReport)
        }


    }, [sortOrder, assessmentSortOrder, setData, setReports])


    const transformEvaluation = (evaluation) =>
        evaluation.flatMap(({ category, processes, average }) =>
            processes.flatMap(({ menuName, selection }) => ({
                category: parseCategory(category),
                processesName: parseProcessName(menuName),
                selection,
                averageSection: average,
            }))
        )

    // Function to calculate average of selections
    const calculateAverageSelection = (selections) =>
        selections.reduce((acc, val) => acc + val, 0) / selections.length

    // Main transformation of data
    const transformData = (data) =>
        data.map((item) => {
            const evaluation = transformEvaluation(item.evaluation)
            const selections = evaluation.map((evalItem) => evalItem.selection)
            const average = calculateAverageSelection(selections)
            const quad = calcAxisSort(item)
            return {
                accessCode: item.accessCode,
                dateAssessed: item.dateAssessed.seconds,
                evaluation,
                vulnerable: quad[1][3],
                average,
            }
        })

    const generateTableData = (data) => {
        const flattenedData = data.flatMap(item =>
            item.evaluation.map(evaluation => ({
                category: evaluation.category,
                process: evaluation.processesName,
            }))
        )

        const uniqueProcesses = [...new Map(flattenedData.map(item => [item.process, item])).values()]

        const dataWithEvaluations = uniqueProcesses.map(processItem => {
            const evaluations = data.reduce((acc, currentItem) => {
                const evaluation = currentItem.evaluation.find(e => e.processesName === processItem.process)
                if (evaluation) acc[currentItem.accessCode] = evaluation.selection
                return acc
            }, {})

            return { ...processItem, ...evaluations }
        })

        dataWithEvaluations.forEach(item => {
            const values = Object.values(item).filter(val => typeof val === 'number')
            const sum = values.reduce((acc, curr) => acc + curr, 0)
            item.average = values.length > 0 ? sum / values.length : 0
        })

        return dataWithEvaluations
    }

    const handleSortByProcess = () => {
        const sortOrderMap = { asc: 'desc', desc: 'default', default: 'asc' }
        const newSortOrder = sortOrderMap[sortOrder] || 'asc'
        setSortOrder(newSortOrder)
    }

    const handleSortByCompany = () => {
        const sortOrderMapping = {
            'default': 'timeOldToNew',
            'timeOldToNew': 'timeNewToOld',
            'timeNewToOld': 'asc',
            'asc': 'desc',
            'desc': 'default'
        }

        // Determine the new sort order
        const newSortOrder = sortOrderMapping[assessmentSortOrder] || 'asc'

        setAssessmentSortOrder(newSortOrder)
    }


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve
                setIsPrinting(true)
            })
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null
            setIsPrinting(false)
        },
        documentTitle: 'vsba-heatmap'
    })


    const PrintHeader = () => {

        return (
            <Box sx={{ display: isPrinting ? 'block' : 'none' }}>
                <Box sx={{ marginBottom: 1, display: 'flex', justifyContent: 'center' }}>
                    <img height="64px" alt="Logo" src="/static/logo.png" />
                </Box>
                <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="body1">
                        Date: {moment().format("MM/DD/YYYY")}
                    </Typography>
                    <Typography variant="body1">
                        Printed By: {`${user.name.first} ${user.name.last}`}
                    </Typography>
                </Box>

                <Box>
                    <Typography sx={{ marginTop: -1, display: 'flex', justifyContent: 'center' }} variant="h4">
                        VIA Heat Map
                    </Typography>
                </Box>

                <Box sx={{ margin: '20px auto 0', display: 'flex', justifyContent: 'space-between', width: '96%' }}>
                    <Typography color="textPrimary" variant="body1">
                        {getProcessSortText()}
                    </Typography>

                    <Typography color="textPrimary" variant="body1">
                        {getCompanySortText()}
                    </Typography>
                </Box>
            </Box>
        )
    }

    const PrintFooter = () => {
        return (
            <Box sx={{ marginTop: 4.8, display: isPrinting ? 'block' : 'none' }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography mt={1} variant="body1">
                        Copyright © {moment().format('YYYY')}  Vector Reports  <span style={{marginLeft: 20}}>Report use requires compliance with Vector Reports Terms and Conditions.</span>
                    </Typography>
                </Box>
                {/* <Footer /> */}
            </Box>
        )
    }


    const getProcessSortText = () => {

        if (sortOrder === 'asc') {
            return 'Low-to-High average process scores'
        } else if (sortOrder === 'desc') {
            return 'High-to-Low average process scores'
        } else {
            return 'Processes in standard order'
        }
    }

    const getCompanySortText = () => {

        if (assessmentSortOrder === 'timeOldToNew') {
            return 'Older-to-Newer date assessment taken'
        } else if (assessmentSortOrder === 'timeNewToOld') {
            return 'Newer-to-Older date assessment taken'
        } else if (assessmentSortOrder === 'asc') {
            return 'Low-to-High average assessment scores'
        } else if (assessmentSortOrder === 'desc') {
            return 'High-to-Low average assessment scores'
        } else {
            return 'Assessments in selection order'
        }
    }
    const PrintHeatMapButton = () => {

        const buttonStyle = {
            background: '#b4c7e7',
            position: 'absolute',
            fontWeight: 'medium',
            right: 200,
            top: 90,
            zIndex: 999999
        }

        // const buttonStyle = (width < 1200) ? {
        //     background: '#b4c7e7',
        //     position: 'absolute',
        //     fontWeight: 'medium',
        //     right: 200,
        //     top: 90,
        //     zIndex: 999999
        // } : {
        //     position: 'absolute',
        //     left: 20,
        //     top: 250,
        //     zIndex: 999999
        // }

        return (
            <Button onClick={handlePrint} sx={buttonStyle}>
                <span>Print Heat Map</span>
            </Button>
        )
    }

    return (
        <>
            <Box sx={{ minHeight: '100%' }} >
                <PrintHeatMapButton />
                <Box sx={{ padding: 2, margin: 'auto', width: '68%', display: 'flex', justifyContent: 'start' }}>
                    <Button
                        component={RouterLink}
                        sx={{ background: '#b4c7e7', fontWeight: 'medium', justifyContent: 'center', textTransform: 'none', width: 50, ml: 1 }}
                        target={'_self'}
                        to={'/app/view'}>
                        <span>
                            Back
                        </span>
                    </Button>
                </Box>

                <Box mt={1} mb={4} sx={{ width: 800, marginLeft: 'auto', marginRight: 'auto' }}>
                    <Typography mb={2} variant="h3">
                        VIA Heat Map Report
                    </Typography>
                    <Box sx={{ margin: 'auto', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ width: '55%', borderTop: '1px solid', borderBottom: '1px solid', padding: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end' }}>
                            <Typography color="textPrimary" variant="body1">
                                {getProcessSortText()}
                            </Typography>
                            <Button onClick={handleSortByProcess} variant="contained" sx={{ ml: 2, height: 50, width: 100, lineHeight: 1.35, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }}> Process Sort </Button>
                        </Box>
                        <Box sx={{ width: '55%', borderLeft: '1px solid', borderTop: '1px solid', borderBottom: '1px solid', padding: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button onClick={handleSortByCompany} variant="contained" sx={{ mr: 2, height: 50, width: 100, lineHeight: 1.35, backgroundColor: '#559f58', '&:hover': { backgroundColor: '#357a38' } }}> Assessment Sort </Button>
                            <Typography color="textPrimary" variant="body1">
                                {getCompanySortText()}
                            </Typography>
                        </Box>
                    </Box>
                    <Box ref={printRef} sx={{ transform: isPrinting ? 'scale(0.8)' : 'scale(1)', marginTop: isPrinting ? -10 : 0 }}>
                        <PrintHeader />
                        <TableContainer sx={{ mt: 1 }} >
                            <Table sx={{
                                width: '100%', [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                    padding: 0,
                                    verticalAlign: 'bottom',
                                }
                            }} size="small" aria-label="a dense table">
                                <TableHead sx={{ 'th:last-child': { borderRight: '1px solid' } }}>
                                    <TableRow>
                                        <TableCell align="left">
                                            <Box style={{ display: 'flex', justifyContent: 'start', alignItems: 'end', borderBottom: '1px solid black' }}>
                                                Category
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box style={{ marginRight: 10, marginLeft: 10, display: 'flex', justifyContent: 'end', alignItems: 'end', borderBottom: '1px solid black' }}>
                                                Process Name
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box style={{ borderBottom: '1px solid black' }}>
                                                Average Process Score
                                            </Box>
                                        </TableCell>
                                        {Object.keys(reports.length > 0 && reports[0]).map((key, index) => {
                                            if (key !== 'process' && key !== 'category' && key !== 'average') {
                                                return <TableCell key={index} sx={{ borderTop: '1px solid', borderLeft: index == 2 && '1px solid' }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <span style={{ textOrientation: 'sideways-right', writingMode: 'sideways-lr' }}>
                                                            {'.... ' + key.slice(-5)}
                                                        </span>
                                                        {Math.round(data.find(d => d.accessCode === key).average * 10) / 10}
                                                    </Box>
                                                </TableCell>
                                            }
                                        })}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reports.map((row, index) => {
                                        return <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{ width: 75 }}>
                                                {row.category}
                                            </TableCell>
                                            <TableCell align='right' >
                                                <Box style={{ marginRight: 10, marginLeft: -20 }}>
                                                    {row.process}
                                                </Box>

                                            </TableCell>
                                            <TableCell align='center' sx={{ width: 75 }}>
                                                {Math.round(row.average * 10) / 10}
                                            </TableCell>

                                            {Object.keys(reports.length > 0 && reports[0]).map(key => {
                                                if (key !== 'process' && key !== 'category' && key !== 'average') {
                                                    return <TableCell key={key} sx={{ width: 52 }}>
                                                        <div style={generateStyle(row[key])}>
                                                            {row[key]}
                                                        </div>
                                                    </TableCell>
                                                }
                                            })}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="body1">
                                Most Vulnerable Capability Quadrant #
                            </Typography>
                            <Box sx={{ display: 'flex' }}>
                                {data.map(item => (
                                    <Box sx={{ border: '1px solid', width: 52, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="body1">
                                            {getQuad(item.vulnerable)}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <PrintFooter />
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    )
}


export default HeatMap