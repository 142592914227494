import {
    CLEAR_ADMIN_DATA, RESET_MSG,
    GET_REPORTS_REQUEST, GET_REPORTS_SUCCESS, GET_REPORTS_FAILURE,
    UPDATE_REPORTS_REQUEST, UPDATE_REPORTS_SUCCESS, UPDATE_REPORTS_FAILURE,
    GET_CODES_REQUEST, GET_CODES_SUCCESS, GET_CODES_FAILURE,
    DELETE_CODE_REQUEST, DELETE_CODES_SUCCESS, DELETE_CODES_FAILURE, GET_ADMIN_ASSESSMENT_HISTORY, GET_ADMIN_ASSESSMENT_HISTORY_SUCCESS, GET_ADMIN_ASSESSMENT_HISTORY_FAILURE,
    GET_ADMIN_AUDIT_HISTORY, GET_ADMIN_AUDIT_HISTORY_SUCCESS, GET_ADMIN_AUDIT_HISTORY_FAILURE
} from '../actions/adminReports';

const assessmentState = {
    //reports
    isReportsLoading: false,
    reports: [],
    getReportError: '',
    //History
    isHistoryLoading: false,
    history: [],
    audit: [], // for audit history
    getHistoryError: '',
    //
    isUpdatingReport: false,
    didReportUpatePass: false,
    didReportUpdateFail: false,
    updateReportError: '',
    //access codes
    accessCodes: [],
    isCodesLoading: false,
    getCodesError: '',
    // isUpdatingReport: false,
    // didReportUpatePass: false, 
    // didReportUpdateFail: fals

}

const adminReports = (state = assessmentState, action) => {
    switch (action.type) {
        case CLEAR_ADMIN_DATA:
            return {
                ...state,
                isReportsLoading: false,
                reports: [],
                getReportError: '',
                isUpdatingReport: false,
                didReportUpatePass: false,
                didReportUpdateFail: false,
                updateReportError: '',
            }
        case RESET_MSG:
            return {
                ...state,
                isReportsLoading: false,
                getReportError: '',
                didReportUpatePass: false,
                didReportUpdateFail: false,
                updateReportError: '',
            }
        //get history
        case GET_ADMIN_ASSESSMENT_HISTORY:
            return {
                ...state,
                isHistoryLoading: true,
            }
        case GET_ADMIN_ASSESSMENT_HISTORY_SUCCESS:
            return {
                ...state,
                history: action.data,
                isHistoryLoading: false,
            }
        case GET_ADMIN_ASSESSMENT_HISTORY_FAILURE:
            return {
                ...state,
                isHistoryLoading: false,
                getHistoryError: action.message
            }
        //get audit history
        case GET_ADMIN_AUDIT_HISTORY:
            return {
                ...state,
                isAuditLoading: true,
            }
        case GET_ADMIN_AUDIT_HISTORY_SUCCESS:
            return {
                ...state,
                audit: action.data,
                isAuditLoading: false,
            }
        case GET_ADMIN_AUDIT_HISTORY_FAILURE:
            return {
                ...state,
                isAuditLoading: false,
                getAuditError: action.message
            }
        //get reports
        case GET_REPORTS_REQUEST:
            return {
                ...state,
                isReportsLoading: true,
            }
        case GET_REPORTS_SUCCESS:
            return {
                ...state,
                reports: action.data,
                isReportsLoading: false,
            }
        case GET_REPORTS_FAILURE:
            return {
                ...state,
                isReportsLoading: false,
                getReportError: action.message
            }

        //update reports
        case UPDATE_REPORTS_REQUEST:
            return {
                ...state,
                isUpdatingReport: true,
                didReportUpatePass: false,
                didReportUpdateFail: false
            }
        case UPDATE_REPORTS_SUCCESS:
            return {
                ...state,
                isUpdatingReport: false,
                didReportUpatePass: true,
                didReportUpdateFail: false
            }
        case UPDATE_REPORTS_FAILURE:
            return {
                ...state,
                isUpdatingReport: false,
                didReportUpatePass: false,
                didReportUpdateFail: true,
                updateReportError: action.message
            }

        //get access codes
        case GET_CODES_REQUEST:
            return {
                ...state,
                isCodesLoading: true,
            }
        case GET_CODES_SUCCESS:
            return {
                ...state,
                accessCodes: action.data,
                isCodesLoading: false,
            }
        case GET_CODES_FAILURE:
            return {
                ...state,
                isCodesLoading: false,
                getCodesError: action.message
            }

        default:
            return state;
    }
}

export default adminReports;