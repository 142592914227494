import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import {
    CircularProgress, Box, Button, Typography, Container, Paper, TextField, Toolbar,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { makeStyles } from '@material-ui/styles'
import TableSpinner from 'src/components/shared/TableSpinner';
import { visuallyHidden } from '@mui/utils';
import PropTypes, { element } from 'prop-types';


//redux
import { connect } from 'react-redux';
import { updateAccessCode, deleteHistory } from 'src/redux/thunks';

const createComparator = (key, order) => (a, b) => {
    const getValue = (obj, path) => (path.split('.').reduce((acc, val) => acc?.[val], obj) || '').toString().toLowerCase();

    const aValue = getValue(a, key);
    const bValue = getValue(b, key);

    if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
    }
    return 0;
};

const EnhancedTableHead = (props) => {
    const [isLoading, setIsLoading] = useState('false');

    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {
                            headCell.id !== 'reports'
                                ? <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                                : headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const AccessCodeTable = (props) => {

    const {
        accessCodes,
        isCodesLoading,
        updateAccessCode,
        deleteHistory
    } = props;


    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('');
    const [editRow, setEditRow] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteCode, setDeleteCode] = useState(null);

    const purchaserRef = useRef(null);
    const ownerRef = useRef(null);
    const assessorRef = useRef(null);
    const costRef = useRef(null);

    const classes = useStyles();


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const formatDate = (seconds) => {
        if (!isNaN(seconds)) {
            const date = moment(seconds * 1000).format('MM/DD/YYYY');
            return date;
        }
        return 0;
    }

    const onSubmit = (row, btnText) => {
        if (btnText === 'Update') {
            //update db
            // console.log( purchaserRef.current, ownerRef.current, assessorRef.current, costRef.current)
            updateAccessCode(row.accessCode, purchaserRef.current, ownerRef.current, assessorRef.current, costRef.current, row.owner.email)
            setEditRow(null)
        } else {
            purchaserRef.current = row?.purchaserEmail
            ownerRef.current = row?.owner?.email
            assessorRef.current = row?.assessor?.email
            costRef.current = row?.cost
            setEditRow(row)
        }
    }

    const onCancel = (row, btnText) => {
        if (btnText === 'Cancel') {
            setEditRow(null)
        } else {
            //delete
            //deleteHistory(row.accessCode)
            setDeleteCode(row.accessCode)
            setOpenDelete(true)
        }
    }

    const handleDialogClose = () => {
        setOpenDelete(false);
    };

    const handleDialogDelete = () => {
        deleteHistory(deleteCode)
        setOpenDelete(false);
    };

    //delete alert
    const DeleteDialog = () => {
        return (
            <Dialog open={openDelete} onClose={handleDialogClose} aria-labelledby="submit-dialog-title" aria-describedby="submit-dialog-description">
                <Box px={5} py={2}>
                    <DialogTitle id="submit-dialog-title" sx={{ display: 'flex', justifyContent: 'center', }}>
                        <Typography gutterBottom variant="h3">Delete Access Code</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="submit-dialog-description" sx={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }} mt={-1}>
                            <Typography color="textPrimary" fontSize={18} gutterBottom variant="body2">
                                Are you sure you want to delete Access Code <b>{deleteCode}</b>
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={handleDialogDelete} variant="contained" sx={{ width: 100 }} color='primary'>
                            Yes
                        </Button>
                        <Button onClick={handleDialogClose} variant="contained" sx={{ width: 100 }} color='secondary' >
                            No
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        )
    }


    return (
        <Container maxWidth>
            <Paper sx={{ width: '100%', marginBottom: 2, marginTop: 6 }}>
                <Toolbar>
                    <Typography variant="h4" id="historyLabel" color="textSecondary" >
                        View and update unused Access Code
                    </Typography>
                </Toolbar>
                <TableContainer sx={{ height: 540 }}>
                    <Table aria-label="purchaseTable" size={'medium'} stickyHeader>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {accessCodes && accessCodes.length > 0 ? (
                                accessCodes.sort(createComparator(orderBy, order))
                                    .map((row, index) => {
                                        const key = `cod-${index}`;
                                        const didComplete = row?.isCompleted
                                        const bg = didComplete ? '#eceff1' : ''
                                        const isEditing = (editRow?.accessCode == row?.accessCode) ? true : false
                                        const btnText = isEditing ? 'Update' : 'Edit'
                                        const btn2Text = isEditing ? 'Cancel' : 'Delete'
                                        const btnColor = isEditing ? 'secondary' : "primary"
                                        const btn2Color = isEditing ? 'secondary' : "secondary"
                                        return (
                                            //<TextField id="standard-basic" label="Standard" variant="standard" />
                                            <TableRow sx={{ background: bg }} key={key}>
                                                <TableCell><Typography >{row?.accessCode}</Typography></TableCell>
                                                <TableCell>{formatDate(row?.date?.seconds)}</TableCell>
                                                <TableCell>
                                                    <Box style={{ maxWidth: 190 }}>
                                                        <Typography >{row?.purchaserEmail}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box style={{ maxWidth: 190 }}>
                                                        {!didComplete && isEditing
                                                            ? <TextField label={ownerRef.current} size="small" fullWidth onChange={e => ownerRef.current = (e.target.value)} />
                                                            : <Typography >{row?.owner?.email}</Typography>
                                                        }
                                                    </Box>
                                                </TableCell>
                                                <TableCell >
                                                    <Box style={{ maxWidth: 190 }}>
                                                        {!didComplete && isEditing
                                                            ? <TextField label={assessorRef.current} size="small" fullWidth onChange={e => assessorRef.current = (e.target.value)} />
                                                            : <Typography >{row?.assessor?.email}</Typography>
                                                        }
                                                    </Box>
                                                </TableCell>
                                                <TableCell >
                                                    <Box style={{ width: 60 }}>
                                                        {!didComplete && isEditing
                                                            ? <TextField label={'$' + costRef.current} size="small" fullWidth onChange={e => costRef.current = (e.target.value)} />
                                                            : <Typography >${row?.cost}</Typography>
                                                        }
                                                    </Box>
                                                </TableCell>
                                                <TableCell >
                                                    <Box style={{ width: 60 }}>
                                                        <Typography >{row?.type}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box style={{ display: 'flex' }}>
                                                        <Button sx={{ width: 80, }} color={btnColor} disabled={didComplete}
                                                            onClick={() => onSubmit(row, btnText)}
                                                        >
                                                            {btnText}
                                                        </Button>
                                                        <Button sx={{ width: 80, }} color={btn2Color} //disabled={didComplete}
                                                            onClick={() => onCancel(row, btn2Text)}
                                                        >
                                                            {btn2Text}
                                                        </Button>
                                                    </Box>

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                            ) : (
                                <TableSpinner isLoading={isCodesLoading} colSpan={headCells.length} />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <DeleteDialog />
        </Container>
    )
}

const headCells = [
    { id: 'accessCode', numeric: false, label: 'Access Code' },
    { id: 'date', numeric: false, label: 'Date' },
    { id: 'purchaserEmail', numeric: false, label: 'Purchaser' },
    { id: 'owner.email', numeric: false, label: 'Owner' },
    { id: 'assessor.email', numeric: false, label: 'Assessor' },
    { id: 'cost', numeric: false, label: 'Cost' },
    { id: 'type', numeric: false, label: 'Type' },
    { id: 'update', numeric: false, label: '' },
];

const useStyles = makeStyles({
    center: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', width: '100%'
    }
});

const mapDispatchToProps = dispatch => ({
    getAccessCodes: () => dispatch(getAccessCodes()),
    updateAccessCode: (code, purchaser, owner, assessor, cost, ownerEmail) => dispatch(updateAccessCode(code, purchaser, owner, assessor, cost, ownerEmail)),
    deleteHistory: code => dispatch(deleteHistory(code)),
});

export default connect(null, mapDispatchToProps)(AccessCodeTable);

