// src/utils/csvUtils.js
export const exportToCSV = (csvString, filename = "export.csv") => {
    if (!csvString || csvString.length === 0) {
        console.error("No data to export.");
        return;
    }

    const blob = new Blob([csvString], { type: "text/csv" });

    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Append the link, trigger the download, then remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
