import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BuyButton from '../Stripe/BuyButton'
import Footer from 'src/components/shared/Footer'
import { Divider } from '@mui/material'
import Grid from '@mui/material/Grid';

const BuyAssessment = () => {


    return (
        <>
            <Helmet>
                <title>Vector Reports | Buy</title>
            </Helmet>
            <Box mt={5} mx={10} sx={{ height: '98%' }}>
                <Container maxWidth='lg'>
                    <Box>
                        <Typography color="textPrimary" gutterBottom variant="h3">
                            Pricing Information Page
                        </Typography>
                        <Typography color="textPrimary" variant="body1" gutterBottom sx={{ mt: 5, mb: 5 }}>
                            This choice represents an investment in yourself and your company to either mark a baseline,
                            identify business process vulnerabilities, or measure progress. It can become your own virtual
                            consulting service.
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography color="textPrimary" variant="h6" sx={{ fontSize: 16 }}>
                                    Services
                                </Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color="textPrimary" variant="h6" sx={{ fontSize: 16 }}>
                                    Purpose
                                </Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={5.5}>
                                <Divider sx={{ mt: 3.2 }} />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color="textPrimary" variant="h6" sx={{ fontSize: 16 }}>
                                    Price
                                </Typography>
                                <Divider />
                            </Grid>

                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <img height={20} alt="Logo" src="/static/images/VIA-logo.png" />
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom>
                                        Designed for the small business.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={5.5} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom sx={{ ml: 3 }}>
                                        The groundbreaking assessment used by business leaders, mentors, consultants, and
                                        agencies looking to evaluate small businesses to identify needed improvements for the
                                        most vulnerable business processes.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={1} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom ml={1}>
                                        $199
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={1.5} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <BuyButton type={"biz"} disabled={false} />
                                </Box>
                                <Divider />
                            </Grid>


                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 100 }}>
                                    <img height={40} alt="Logo" src="/static/images/VIA-Audit.png" />
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 100 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom>
                                        Designed for the small business support organizations.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={5.5} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 100 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom sx={{ ml: 3 }}>
                                        All the benefits of the VIA-Business assessment, PLUS: 1) a detailed Prescription report
                                        on the most-vulnerable business process, along with learning objectives tailored to the
                                        rated level of maturity, and 2) a required, expanded field for “rating evidence /
                                        justification” used for creating improvement treatments and/or for auditing purposes.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={1} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 100 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom ml={1} >
                                        $249
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={1.5} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 100 }}>
                                    <BuyButton type={"audit"} disabled={false} />
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <img height={20} alt="Logo" src="/static/images/VIA-ITAB.png" />
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom>
                                        SBIR &amp; STTR proposal readiness.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={5.5} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom sx={{ ml: 3 }}>
                                        An assessment that helps your team increase their likelihood of success when applying
                                        for SBIR / STTR funding opportunities.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={1} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom ml={1}>
                                        Future
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={1.5} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <BuyButton type={"audit"} disabled={true} />
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <img height={20} alt="Logo" src="/static/images/VIA-Readiness.png" />
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom>
                                        Designed for the wana-preneur.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={5.5} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom sx={{ ml: 3 }}>
                                        An assessment that helps illuminate the way for those thinking about starting a business.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={1} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBotto ml={1}m>
                                        Future
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={1.5} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <BuyButton type={"audit"} disabled={true} />
                                </Box>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mt: 5 }} >
                        <Typography color="red" variant="body1" gutterBottom>
                            Note: The email you enter in Stripe will become the account owner and they will be sent the
                            Stripe receipt.
                        </Typography>
                        <Typography color="textPrimary" variant="body1" gutterBottom sx={{ mt: 2 }}>
                            Please know the Owner can transfer ownership on the My Panel page to another Owner as
                            needed.
                        </Typography>
                        {/* <Typography color="textPrimary" variant="body1" gutterBottom sx={{ mt: 2 }}>
                            Please click the button below to be taken our Stripe Payment Processor where you can select
                            the assessments you want to purchase.
                        </Typography>


                        <BuyButton /> */}
                    </Box>
                </Container>
            </Box>
            <Footer />
        </>
    )
}




export default BuyAssessment