import React, { useEffect, useState } from 'react'
//redux
import { useSelector, useDispatch } from 'react-redux'
//ui
import { Helmet } from 'react-helmet-async'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { File as FileIcon, Save as SaveIcon } from 'react-feather'
import PDFUploader from 'src/components/admin/PrescriptionCreator/PDFUploader'

const Files = () => {


    return (
        <>
            <Helmet>
                <title>Admin | File Uploader</title>
            </Helmet>
            <Box sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth='lg' >
                    <Typography color="textPrimary" variant="h2" >
                        File Uploader
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2" >
                        View and edit upload files below
                    </Typography>
                </Container>
                {/* Table */}
                <Box mt={4}>
                    <Container maxWidth='lg'>

                        <Paper sx={{ width: '100%', marginBottom: 2 }}>
                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}
                            >
                                <List dense={false} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <ListItem
                                        secondaryAction={
                                           <Button variant='contained' color="primary" >Upload</Button>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FileIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Success Guide"
                                            secondary={'Uploaded on: Secondary text'}
                                        />
                                    </ListItem>
                                    <ListItem
                                        secondaryAction={
                                           <Button variant='contained'  color="primary">Upload</Button>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FileIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="FAQ Document"
                                            secondary={'Uploaded on: Secondary text'}
                                        />
                                    </ListItem>
                                    <ListItem
                                        secondaryAction={
                                           <Button variant='contained' color="primary">Upload</Button>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FileIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Terms And Conditions"
                                            secondary={'Uploaded on: Secondary text'}
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                        </Paper>
                    </Container>
                </Box>
            </Box>
        </>
    )

}

export default Files;