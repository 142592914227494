import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import EnhancedTable from 'src/components/shared/EnhancedTable';
import { exportToCSV } from 'src/libs/csvUtils';
import moment from 'moment';
import { createPrescriptionPDF } from 'src/views/app/ViewReports/generateAuditPDF';
import { findMostVulnerableProcess, transformData, generateCSVData, generateExportData } from './utils';
import { getPrescription } from 'src/redux/thunks';




const Audit = ({ type }) => {
    const userInfo = useSelector((state => state.auth.userInfo))
    const history = useSelector((state) => state.assessment.assessmentHistory || []);
    const isLoading = useSelector((state) => state.assessment.isAssessmentLoading || false);

    const [data, setData] = useState([])
    const [showPDF, setShowPDF] = useState(null)

    useEffect(() => {
        if (history) {
            setData(history.filter(item => item.type === 'VIA Audit'))
        }
    }, [history])

    const createPrescription = async (items) => {
        console.log("Creating prescriptions for items: ", items)
        try {
            await Promise.all(items.map(async (item) => {
                try {
                    let process = findMostVulnerableProcess(item);
                    let prescriptionContent = await getPrescription(process.processName);
                    let prescription = prescriptionContent[0].data?.find(d => d.name === process.selection.toString())

                    const dynamicContent = {
                        taxonomy: prescription.taxonomy,
                        assessor: item.assessor?.name,
                        notes: process.notes,
                        inihibitor: process.inihibitor,
                        selection: process.selection,
                        bodyText: prescription.content,
                        name: process.processName,
                        dateAssessed: item.dateAssessed.seconds,
                        organization: item.organizationAssessed
                    };

                    return await createPrescriptionPDF(dynamicContent)

                } catch (error) {
                    console.error(`Error processing item ${item}:`, error);
                }
            }));

        } catch (error) {
            console.error("Error in createPrescription:", error);
        }
    };


    const handleExport = (selectedItems) => {
        const transformedData = transformData(selectedItems)
        const csvData = generateExportData(transformedData, selectedItems)

        // console.log("csvData: ", csvData)

        exportToCSV(csvData, `VIA-exported-data-by-${userInfo.email}.csv`);
    }


    const handleExportToCSV = (selectedItems) => {

        const transformedData = transformData(selectedItems)

        const csvData = generateCSVData(transformedData)

        exportToCSV(csvData, `VIA-Business_Audit_DataExport_By_${userInfo.email}.csv`);
    };

    const createTitle = useMemo(() => {
        const action = type === 'prescription' ? 'create prescriptions' : 'add to the export file';
        return <>Select which Assessments to <b>{action}</b></>;
    }, [type]);

    const headCells = useMemo(() => [
        { id: 'organizationAssessed', numeric: false, disablePadding: false, label: 'Company Assessed' },
        { id: 'project', numeric: false, disablePadding: false, label: 'Project Name' },
        { id: 'dateAssessed', numeric: true, disablePadding: false, label: 'Assessed Date' },
        { id: 'code', numeric: false, disablePadding: false, label: 'Code' },
        { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
        { id: 'assessorSortName', numeric: false, disablePadding: false, label: 'Assessor' },
        { id: 'owner', numeric: false, disablePadding: false, label: 'Owner' },
        {
            id: 'include',
            numeric: true,
            disablePadding: false,
            label: type === 'prescription' ? 'Select a Company' : 'Select to Include',
        },
    ], [type]);

    return (
        <Box sx={{ minHeight: '100%' }}>
            <Box mt={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    sx={{ fontSize: 20, textAlign: 'center', ml: 27, mb: 1 }}
                >
                    {createTitle}
                </Typography>
                <EnhancedTable
                    headCells={headCells}
                    rows={data}
                    isLoading={isLoading}
                    openSelected={type === 'prescription' ? createPrescription : handleExportToCSV}
                    onExportGrouping={handleExport}
                    checkBox
                    apiButton={true}
                    toolbarButtonText={type === 'prescription' ? 'Create Prescription' : 'Export Heat Map'}
                />
            </Box>
            <Box>
                {showPDF &&
                    <PDFViewer width={600} height={700}>
                        <AuditPDF />
                    </PDFViewer>
                }
            </Box>
        </Box>
    );
};

export default Audit;
