import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import moment from 'moment'

const styles = {
    container: {
        margin: 'auto', width: 500
    },
    content: {
        padding: "0px !important"
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'end',
        p: 2,
        paddingBottom: 3
    },
    body: {
        bgcolor: '#f4f6f8',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        p: 2,
    },
    footer: {
        p: 1.2
    }
}

const Receipt = ({ data, amount, codes, type, innerRef }) => {

    const renderCodes = () => {
        return (
            <>
                {codes?.map((code, index) => (
                    <>
                        <Typography color="textPrimary" gutterBottom variant="body1">{code}</Typography>
                        {index !== codes.length - 1 && <Divider style={{ width: 140, marginLeft: 10, marginBottom: '0.35em', display: 'block' }} />}
                    </>
                ))}
            </>
        )
    }

    return (
        <Box sx={styles.container} ref={innerRef}>
            <Card variant="outlined">
                <CardContent sx={styles.content}>
                    <Box sx={styles.header} >
                        <img height="70px" alt="Logo" src="/static/logo.png" />
                        <Typography variant='h4' color="textPrimary" gutterBottom>
                            Assessment Purchase Receipt
                        </Typography>
                    </Box>
                    <Grid sx={styles.body} container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                Owner email account ...............
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                {data ? data?.customer_details.email.toLowerCase() : '####'}
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                Item purchased .........................
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                {type === 'audit' ? 'VIA Audit Assessment' : 'VIA·Business Assessment'}
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                Quantity purchased ...................
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                {codes.length}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                Unique access codes ..................
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          { renderCodes() }
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                Amount Paid ..............................
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                {amount}
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                Date purchased ...........................
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1' color="textPrimary" gutterBottom>
                                {moment(Date.now(data?.created)).format('MM/DD/yyyy')}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box sx={styles.footer}>
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                            Please keep this for your record of Access Code purchases.
                        </Typography>
                    </Box>

                </CardContent>
            </Card>
        </Box>
    )
}

export default Receipt