import moment from 'moment';
import { PDFDocument, rgb, PageSizes } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

import calibriFontPath from '../../../assets/calibri-regular.ttf';
import calibriBoldFontPath from '../../../assets/calibri-bold.ttf';
import calibriItalicFontPath from '../../../assets/calibri-italic.ttf';
import viaLogoImage from '../../../assets/logo.png';
import logoImage from '../../../assets/VIA-Audit.png';

import { storage } from 'src/services/firebase';

// Utility function for adding header
const addHeader = (pages, customFont, date, company, assessor) => {

    pages.forEach((page) => {
        const { width, height } = page.getSize();

        page.drawText(`${moment.unix(date).format('MM/DD/YYYY')}`, {
            x: width / 2 - 35,
            y: height - 64,
            font: customFont, // Ensure this is a PDFFont
            size: 10,
        });

        page.drawText(`Assessor: ${assessor}`, {
            x: width - 200,
            y: height - 55,
            font: customFont, // Ensure this is a PDFFont
            size: 10,
        });

        page.drawText(`Company: ${company}`, {
            x: width - 200,
            y: height - 66,
            font: customFont, // Ensure this is a PDFFont
            size: 10,
        });
    });
};

// Utility function for adding notes
const addNotes = (lastPage, customFont, notes, logo, viaLogo) => {
    const { width, height } = lastPage.getSize();

    const pngDims = logo.scale(0.044)
    const logoDims = viaLogo.scale(0.044)

    lastPage.drawImage(logo, {
        x: 60,
        y: height - 70,
        width: pngDims.width,
        height: pngDims.height,
    })


    lastPage.drawText(notes, {
        x: 50,
        y: height - 275, // Adjust as necessary
        size: 11,
        font: customFont, // Ensure this is a PDFFont
        color: rgb(0, 0, 0), // Black text
        maxWidth: width - 100, // Wrap text within the page margins
        lineHeight: 14,
    });

    lastPage.drawImage(viaLogo, {
        x: 65,
        y: 45,
        width: logoDims.width,
        height: logoDims.height,
    })

    lastPage.drawText('Proprietary & Confidential – Do Not Disclose              Copyright © 2025 Vector Reports                           4 ', {
        x: 150,
        y: 50, // Adjust as necessary
        size: 8.5,
        font: customFont, // Ensure this is a PDFFont
        color: rgb(0, 0, 0), // Black text
        maxWidth: width, // Wrap text within the page margins
        lineHeight: 14,
    });
};

const addStaticText = async (lastPage, customFont, customBoldFont, customItalicFont) => {

    const { width, height } = lastPage.getSize();

    lastPage.drawText("Notes", {
        x: 50,
        y: height - 100, // Adjust as necessary
        size: 11,
        font: customBoldFont, // Ensure this is a PDFFont
        color: rgb(0, 0, 0), // Black text
        maxWidth: width - 100, // Wrap text within the page margins
        lineHeight: 14,
    })

    lastPage.drawText("During the assessment, the assessor furnished justification notes describing why they selected this maturity level. These notes are from the rated most-vulnerable process.", {
        x: 50,
        y: height - 120, // Adjust as necessary
        size: 11,
        font: customFont, // Ensure this is a PDFFont
        color: rgb(0, 0, 0), // Black text
        maxWidth: width - 100, // Wrap text within the page margins
        lineHeight: 14,
    })


    lastPage.drawText("The assessor might add some additional clarification notes on what else is needed beyond this Prescription to mature this business process to the next higher level. Use this content appropriately and do not rely upon it until after validating its accuracy.", {
        x: 50,
        y: height - 155, // Adjust as necessary
        size: 11,
        font: customFont, // Ensure this is a PDFFont
        color: rgb(0, 0, 0), // Black text
        maxWidth: width - 100, // Wrap text within the page margins
        lineHeight: 14,
    })

    lastPage.drawText("Incorporate noted and appropriate “missings” associated with this business process into the improvement solution implementation, and measured when the improvement effort is complete.", {
        x: 50,
        y: height - 205, // Adjust as necessary
        size: 11,
        font: customFont, // Ensure this is a PDFFont
        color: rgb(0, 0, 0), // Black text
        maxWidth: width - 100, // Wrap text within the page margins
        lineHeight: 14,
    })

    lastPage.drawText("Notes below are from the Assessment’s assessor:", {
        x: width / 2 - 100,
        y: height - 250, // Adjust as necessary
        size: 11,
        font: customItalicFont, // Ensure this is a PDFFont
        color: rgb(0, 0, 0), // Black text
        maxWidth: width - 100, // Wrap text within the page margins
        lineHeight: 14,
    })
}

export const createPrescriptionPDF = async (dynamicContent) => {
    if (!dynamicContent) {
        console.error('No content provided to generate the PDF.');
        return;
    }

    try {

        const pdfRef = storage.ref(`prescriptions/${dynamicContent.name}/${dynamicContent.selection}/content.pdf`);
        // Get the download URL
        const fileUrl = await pdfRef.getDownloadURL();

        const existingPdfBytes = await fetch(fileUrl).then((res) => res.arrayBuffer());
        const fontBytes = await fetch(calibriFontPath).then((res) => res.arrayBuffer());
        const calibriBoldBytes = await fetch(calibriBoldFontPath).then((res) => res.arrayBuffer());
        const calibirItalicBytes = await fetch(calibriItalicFontPath).then((res) => res.arrayBuffer());

        const pngImageBytes = await fetch(logoImage).then((res) => res.arrayBuffer())
        const logoBytes = await fetch(viaLogoImage).then((res) => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(fontkit);

        const customFont = await pdfDoc.embedFont(fontBytes, {
            subset: true,
            features: { liga: false },
        });

        const customBoldFont = await pdfDoc.embedFont(calibriBoldBytes, {
            subset: true,
            features: { liga: false },
        });

        const customItalicFont = await pdfDoc.embedFont(calibirItalicBytes, {
            subset: true,
            features: { liga: false },
        });

        const viaLogo = await pdfDoc.embedPng(logoBytes)
        const logoImg = await pdfDoc.embedPng(pngImageBytes)

        pdfDoc.addPage(PageSizes.Letter);

        const pages = pdfDoc.getPages();
        const lastPage = pages[pages.length - 1];

        addHeader(pages, customFont, dynamicContent.dateAssessed, dynamicContent.organization, dynamicContent.assessor);

        addStaticText(lastPage, customFont, customBoldFont, customItalicFont)

        addNotes(lastPage, customFont, dynamicContent.notes, logoImg, viaLogo)

        const pdfBytes = await pdfDoc.save()

        const blob = new Blob([pdfBytes], { type: 'application/pdf' })
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Prescription-${dynamicContent.name}.pdf`
        link.click();
        window.URL.revokeObjectURL(link.href);

    } catch (error) {
        console.error('Error generating PDF:', error);
    }
};
